section {
  position: absolute;
  right: 0;
  z-index: 1;
  .notification {
    font-size: 20px;
    padding: 50px 80px;
    margin: 20px;
    border-radius: 5px;
  }
}

.notification h2,
.notification p {
  font-size: 3rem;
  margin: 0;
}

.error {
  background-color: #690000;
  opacity: 0.9;
  color: white;
}

.success {
  background-color: #1ad1b9;
  opacity: 0.9;
  color: black;
}
