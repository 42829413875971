.thumbsContainer {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  margin-top: 16px;
}

.thumb {
  position: relative;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.document{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;


  .remove-image {
    position: relative;
    top: unset;
    right: unset;
    margin-left: 5px;
  }
}

.img {
  display: block;
  width: auto;
  height: 100%;

}

.remove-image{
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  top: -10px;
  right: -10px;
}


.rename-documents-hover {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
}

.rename-documents-hover:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  transform: translate(-140%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.8;
  border-radius: 50%;
  transition: 0.8s;
}

.rename-documents-hover:hover:after {
  transform: translate(-50%, -50%);
}

.eye {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-2000px, -50%);
  color: #fff;
  transition: 0.4s;
  transition-timing-function: ease-in;
}

.rename-documents-hover:hover .eye {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}