* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.main-logo {
  margin-right: 20px;
  color: rgb(219, 219, 219);
}

.icon {
  margin-right: 7px;
  color: rgb(108, 122, 145);
}

.icon-tabler-trash,
.icon-tabler-pencil {
  margin: 0px !important;
}

.objave {
  .nav-item {
    flex: none;
  }
}

.modal-new-cat {
  .nav-item {
    flex: none;
  }
}

.title-first {
  margin-bottom: 20px;
}

.nav-komisija {
  color: black;
  &:hover {
    text-decoration: none;
  }
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}

.form-label .invalid {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.loading-indicator:before {
  content: "";
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: "Loading...";
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.card {
  margin-bottom: 20px;
}

.list-center p {
  margin: 0;
  width: 600px;
}

.title {
  width: 300px;
  margin-bottom: 0;
}

.form-error-message {
  color: red;
  font-size: 10px;
}

.form-required-filed {
  color: red;
}

.pagination {
  margin-bottom: 0;
}

.page-item {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.serach-icon {
  width: 30px;
  height: inherit;
  border-left: 1px solid red;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
}

.button-search {
  position: absolute;
  right: 5px;
  top: 7px;
}

.cursor-pointer{
  cursor: pointer;
}

.react-datepicker-wrapper{
width: 100% !important;
}

.img-logo-cg {
  width: 35px;
  margin-right: 30px;
}

.img-logo-cg-login {
  width: 70px;
}