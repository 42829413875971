.multi-select-choises{
    width: 100%;
    border: 1px solid #e6e7e9;
    border-radius: 4px;
    list-style-type: none;
    padding-left: 7px;
    max-height: 200px;
    overflow-y: scroll;
}
.multi-select-title{
    pointer-events: none;
    font-weight: bold;
}

.multi-select-item{
    padding: 5px;
    cursor: pointer;

    &:hover{
        background-color: rgb(164, 170, 179);
        opacity: 0.5;
        color: white;
    }
}
